//const baseUrl = "http://localhost/api/v1";
const baseUrl = "https://booking.nacellegrooves.com/api/v1";
//const baseUrl = "https://booking.sandboxfestival.com/api/v1";
//const baseUrl = "https://stagingevents.nacellegrooves.com/api/v1";

//export const homepage_link = "http://localhost:3000";
export const homepage_link = "https://booking.nacellegrooves.com";
//export const homepage_link = "https://booking.sandboxfestival.com";
//export const homepage_link = "https://stagingevents.nacellegrooves.com";

const authUrl = `${baseUrl}/auth`;
const customersUrl = `${baseUrl}/customers`;
const eventsUrl = `${baseUrl}/events`;
const cartUrl = `${baseUrl}/carts`;
const paymentUrl = `${baseUrl}/ticket_order_payments`;
const orderUrl = `${baseUrl}/ticket_orders`;
const upgradeUrl = `${baseUrl}/ticket_upgrade_orders`;


export const routes = {
  base: baseUrl,

  login: `${customersUrl}/login/`,
  register: `${customersUrl}/signup/`,
  logout: `${authUrl}/logout/`,
  forgotPassword: `${customersUrl}/forgot-password/`,
  resetPassword: `${customersUrl}/reset-password/`,
  me: `${authUrl}/me/`,
  changePassword: `${authUrl}/change-password/`,
  changeInfo: `${customersUrl}/update-profile/`,

  eventsList: `${eventsUrl}/`,
  getEventById: (id) => `${eventsUrl}/${id}/`,
  ticketTiers: (id) => `${eventsUrl}/${id}/ticket-tiers/`,
  addons: (id) => `${eventsUrl}/${id}/add-ons/`,

  addTicketsToCart: `${cartUrl}/anonymous/`,
  cancelCart: `${cartUrl}/cancel/`,

  getOrderItems: (id) => `${orderUrl}/${id}`,
  ordersList: `${orderUrl}/`,

  payCart: `${paymentUrl}/`,
  submitReservation: `${baseUrl}/prepaid_reservation_orders/order/`,
  checkPayment:`${baseUrl}/prepaid_reservation_orders/check-payment/`,
  getReservations: `${baseUrl}/prepaid_reservation_orders`,
  getReservationsById: (id) => `${baseUrl}/prepaid_reservation_orders/${id}/`,
  getFriendOrder: (id, token) =>
    `${baseUrl}/prepaid_reservation_orders/${id}/anonymous_order/?token=${token}`,
  payReservationAnonymously: (reservation, order_id) =>
    `${baseUrl}/reservations/${reservation}/orders/${order_id}/payments/anonymous/`,

  getTicketUpgradeOrder: (token) => `${upgradeUrl}/${token}/`,

  getInvitationOrder: (token) => `${baseUrl}/invitations/${token}/`,
  sendInvitationOrder: (id, portal) =>
    `${baseUrl}/invitations/${id}/${portal}/`,
  getInvitationReceipt: (id) => `${baseUrl}/prepaid_reservation_orders/${id}/`,
  payReservation: (reservation, order) =>
    `${baseUrl}/reservations/${reservation}/orders/${order}/payments/`,

  faqsList: `${baseUrl}/faqs/`,

  fetchUser: (email) => `${customersUrl}/email/?email=${email}`,

  anonymousRegister: `${customersUrl}/anonymous/`,
  createUser: `${customersUrl}/`,
};

export const AXIOS_DEFAULT_CONFIG = {
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  contentType: "application/json",
};

export const VAT = 0.14;
export const entertainmentTax = 0.1;

export const lockUserTicket = false;
export const ticketLimit = 4;
export const warnAt = 3;

//export const redirect_to = "http://localhost";
//export const redirect_to = "https://booking.nacellegrooves.com";
//export const redirect_to = "https://booking.sandboxfestival.com";
//export const redirect_to = "https://stagingevents.nacellegrooves.com";

export const seatsio_public_key = "20c7144b-0b02-4c37-be47-421be7be0778";

export const hardcoded_event = null;

export const min_payment = 10;

//Stripe test key pk_test_51N08jtLL3r41ZoBN6bI2MoP621LR1MWp1yzzJfisrIHytwFCJmFnib1bvJYlhxe0NEEQIh88hUMyxC8QIKvacaOi00C4Z9uClF
//Stripe prod key pk_live_51N08jtLL3r41ZoBNolS6BgOx48oYzh9cNzhLKb2A84yRcl0kEagStXtQx7LFXU2E0usE0gsaAodYvbVUuXPpHWXl00GyrJXzdu

export const stripe_publishable_id =
  "pk_live_51N08jtLL3r41ZoBNolS6BgOx48oYzh9cNzhLKb2A84yRcl0kEagStXtQx7LFXU2E0usE0gsaAodYvbVUuXPpHWXl00GyrJXzdu";

export const currency = "EGP";

export const rights = "© 2024 Nacelle. All rights reserved";